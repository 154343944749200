import React from "react";
import ReactDOM from "react-dom";
import CssBaseline from "@material-ui/core/CssBaseline";
import { SetConfigProducao } from "./config";

const GetConfigProducao = async () => {
  try {
    const response = await fetch("/config");
    const data = await response.json();
    return data;
  } catch (error) {
    return null;
  }
};

// Chame a função assíncrona dentro de uma IIFE (Immediately Invoked Function Expression)
(async () => {
  try {
    SetConfigProducao(await GetConfigProducao());

    //Importe o componente App dinamicamente apenas após a busca de configuraçao
    const { default: App } = await import("./App");
    ReactDOM.render(
      <CssBaseline>
        <App />
      </CssBaseline>,
      document.getElementById("root")
    );
  } catch (error) {
    console.error("Erro durante a inicialização:", error);
  }
})();
