// configurações modo produção (BEMCHAT_) ------------------------------
let configProd = null;
export function SetConfigProducao(newConfig) {
  configProd = newConfig;
}

// lendo configurações REACT_APP_ (modo desenvolvimento) ---------------------
function getConfig(name, defaultValue = null) {
  // If inside a docker container, use window.ENV

  if (window.ENV !== undefined) {
    return window.ENV[name] || defaultValue;
  }

  return process.env[name] || defaultValue;
}

// ---------------------------------------------------------------------------
export function getPageTitle() {
  let result = null;

  if (configProd) {
    result = configProd.BEMCHAT_PAGE_TITLE;
  } else {
    result = getConfig("REACT_APP_PAGE_TITLE");
  }
  //console.log("CONFIG :: getPageTitle", result);

  return result;
}

export function getBackendUrl() {
  let result = null;

  if (configProd) {
    result = configProd.BEMCHAT_BACKEND_URL;
  } else {
    result = getConfig("REACT_APP_BACKEND_URL");
  }
  //console.log("CONFIG :: getBackendUrl", result);

  return result;
}

export async function getHoursCloseTicketsAuto() {
  return getConfig("REACT_APP_HOURS_CLOSE_TICKETS_AUTO");
}
